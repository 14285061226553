import { useCallback, useEffect, useRef, useState } from 'react';

import { QueueLoader } from 'components/ThreeView/utils/loader/QueueLoader';
import { WebGLContainer } from 'components/ThreeView/utils/webglContainer';
import { usePreloadDirectory } from 'u9/hooks';

import { EXPERIENCE, LANDING } from './queues';

export const preloadTypes = {
  LANDING: 'landing',
  EXPERIENCE: 'experience',
};
export const validTypes = [
  preloadTypes.LANDING,
  preloadTypes.EXPERIENCE,
] as const;

export default function PreloaderService(
  type: typeof validTypes[number],
  theme: any,
  progressCallback: (value: number) => void,
  completeCallback: () => void
) {
  const queueLoader = useRef<QueueLoader>(null);

  const [isLoaded, setLoaded] = useState<boolean>(false);
  const directoryLoadProgress = usePreloadDirectory(
    type === preloadTypes.EXPERIENCE ? EXPERIENCE : LANDING
  );
  const [modelsLoadProgress, setLoadModelsProgress] = useState<number>(0);
  const [mixedLoadProgress, setMixedProgress] = useState<number>(0);

  const load3DAssets = useCallback(() => {
    console.log('load3DAssets', theme);
    queueLoader.current = new QueueLoader(theme);
    queueLoader.current.addEventListener('on_complete', onQueueLoaderComplete);
    queueLoader.current.addEventListener('on_progress', onQueueLoaderProgress);
    queueLoader.current.load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onQueueLoaderProgress = details => {
    const perc = details.data;
    setLoadModelsProgress(perc);
  };

  const onQueueLoaderComplete = () => {
    WebGLContainer.loaded = true;
  };

  useEffect(() => {
    const _progress =
      (directoryLoadProgress / 100 + modelsLoadProgress / 100) / 2;
    progressCallback(_progress);
    setMixedProgress(_progress);
  }, [directoryLoadProgress, modelsLoadProgress, progressCallback]);

  useEffect(() => {
    if (mixedLoadProgress >= 1 && !isLoaded) {
      setLoaded(true);

      completeCallback();
    }
  }, [mixedLoadProgress, isLoaded, completeCallback]);

  useEffect(() => {
    if (type === preloadTypes.EXPERIENCE) {
      load3DAssets();
    } else {
      setLoadModelsProgress(100);
    }
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    return () => {};
  }, [load3DAssets, type]);
}
