export const LANDING = [
  'assets/img/',
  'assets/img/can/',
  'assets/img/errorModals/',
  'assets/img/errorPages/',
  'assets/img/galleryPicker/',
  'assets/img/intro/hash/',
  'assets/img/sharable',
];
export const EXPERIENCE = ['assets/img/can/'];
