const THREE = require('three');

import { WebGLContainer } from '../webglContainer';
import Preloader from './Preloader';

import { PROD_PATH } from 'config';

export class QueueLoader extends THREE.EventDispatcher {
  preloader;
  queue;
  theme;

  constructor(theme) {
    super();
    this.theme = theme;
  }

  async buildQueue() {
    console.log(this.theme);
    const tempQueue = [];
    this.queue = [];

    this.queue = tempQueue.concat([
      // models
      {
        name: 'can',
        url: this.theme.three.can,
        type: 'gltf',
      },
      {
        name: 'canLogo',
        url: this.theme.three.canLogo,
        type: 'texture',
      },
      {
        name: 'canOriginal',
        url: this.theme.three.canOriginal,
        type: 'texture',
      },
      {
        name: 'canAi',
        url: this.theme.three.canAi,
        type: 'texture',
      },
      {
        name: 'particle',
        url: this.theme.three.particle,
        type: 'texture',
      },
      {
        name: 'particleHorizontal',
        url: this.theme.three.particleHorizontal,
        type: 'texture',
      },
      {
        name: 'particleHorizontal',
        url: this.theme.three.particleHorizontal,
        type: 'texture',
      },
      {
        name: 'sharableLines_en',
        url: this.theme.three.sharableLinesEn,
        type: 'texture',
      },
      {
        name: 'sharableLines_es',
        url: this.theme.three.sharableLinesEs,
        type: 'texture',
      },
      {
        name: 'sharableLines_vi',
        url: this.theme.three.sharableLinesVi,
        type: 'texture',
      },
      {
        name: 'sharableLines_pk',
        url: this.theme.three.sharableLinesPk,
        type: 'texture',
      },
      // HDR
      {
        name: 'envMap',
        type: 'hdr',
        url: `${PROD_PATH}/models/textures/room.hdr`,
      },
    ]);
  }

  load() {
    this.preloader = new Preloader();
    this.preloader.debug = false;
    this.preloader['addEventListener']('on_complete', details =>
      this.loadComplete(details)
    );
    this.preloader['addEventListener']('on_progress', details =>
      this.loadProgress(details)
    );

    this.buildQueue();
    this.preloader.queue(this.queue);
  }

  loadProgress(details) {
    console.log(`Preloader: ${details.data * 100}%`);
    this['dispatchEvent']({
      type: 'on_progress',
      data: details.data * 100,
    });
  }

  async loadComplete(details) {
    WebGLContainer.dictionary = details.data;

    WebGLContainer.loaded = true;
    this.triggerComplete();
  }

  triggerComplete() {
    if (WebGLContainer.loaded) {
      this['dispatchEvent']({
        type: 'on_complete',
        data: WebGLContainer.dictionary,
      });
    }
  }
}
