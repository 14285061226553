const THREE = require('three');

import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader';
import { EXRLoader } from 'three/examples/jsm/loaders/EXRLoader';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader';

import { PROD_PATH } from 'config';
export default class Preloader extends THREE.EventDispatcher {
  manager;
  gltf;
  hdr;
  exr;
  texture;
  audio;
  assets;
  debug;
  constructor() {
    super();

    this.manager = new THREE.LoadingManager();

    this.gltf = new GLTFLoader(this.manager);
    this.gltf['setCrossOrigin']('anonymous');
    this.gltf.setDRACOLoader(
      new DRACOLoader(this.manager).setDecoderPath(`${PROD_PATH}/vendor/wasm/`)
    );

    this.hdr = new RGBELoader(this.manager);
    this.exr = new EXRLoader(this.manager);
    this.texture = new THREE.TextureLoader(this.manager);
    this.audio = new THREE.AudioLoader(this.manager);
    this.assets = {};

    this.debug = true;

    this.setListeners();
  }

  setListeners() {
    this.manager.onStart = this.onStart.bind(this);
    this.manager.onLoad = this.onLoad.bind(this);
    this.manager.onProgress = this.onProgress.bind(this);
    this.manager.onError = this.onError.bind(this);
  }

  queue(list) {
    if (list.length < 0) return;
    for (const item of list) {
      if (this[item.type] && item.url.length > 0)
        this[item.type].load(item.url, result => {
          if (!this.assets[item.name]) {
            this['dispatchEvent']({
              type: 'on_item_complete',
              data: {
                id: item.name,
                src: result,
              },
            });
            this.assets[item.name] = result;
          }
        });
    }
  }

  onStart(url, itemsLoaded, itemsTotal) {
    if (this.debug)
      console.log(
        'Started loading file: ' +
          url +
          '.\nLoaded ' +
          itemsLoaded +
          ' of ' +
          itemsTotal +
          ' files.'
      );
  }

  onLoad() {
    if (this.debug) console.log('Loading complete!', this.assets);
    this['dispatchEvent']({
      type: 'on_complete',
      data: this.assets,
    });
  }

  onProgress(url, itemsLoaded, itemsTotal) {
    if (this.debug)
      console.log(
        'Loading file: ' +
          url +
          '.\nLoaded ' +
          itemsLoaded +
          ' of ' +
          itemsTotal +
          ' files.'
      );
    this['dispatchEvent']({
      type: 'on_progress',
      data: itemsLoaded / itemsTotal,
    });
  }

  onError(url) {
    if (this.debug) console.log('There was an error loading ' + url);
  }
}
